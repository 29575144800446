import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import alertImg from '../../../../Assets/Images/Lashic/Alert.png';
import taskImg from '../../../../Assets/Images/Lashic/Task.png';
import customersImg from '../../../../Assets/Images/Lashic/Customers.png';
import customerDetailImg from '../../../../Assets/Images/Lashic/CustomerDetail.png';

const Task = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 15}%)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -45vh;
left:30vw;
/* border: 1px dashed red; */
height: 40vh; 
`;

const Alert = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 8}%) scale(0.9)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom:5vh;
right: 17vw;
/* border: 1px dashed red; */
height: 40vh;
`;

const CustomerDetail = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 10}%) scale(0.7)`,
  }),
})`
transition: transform 0.2s ease-out;
bottom:-65vh;
left:20vw;
position: absolute;
/* border: 1px dashed red; */
height: 40vh;
`;

const Customers = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 2}%) scale(0.6)`,
  }),
})`
transition: transform 0.2s ease-out;
bottom:-45vh;
right: 20vw;
position: absolute;
/* border: 1px dashed red; */
height: 80vh;
`;

class LashicImages extends Component {
  render() {
    let { scrollPercent } = this.props;
    const {
      boxHeight, index, scrollHeight, screenHeight,
    } = this.props;
    const heighttoBeReducedinVH = ((boxHeight * index) - 100);
    const scrollOffset = (screenHeight * heighttoBeReducedinVH) / 100;
    const scrollOffsetInPercent = (scrollOffset * 100 / scrollHeight) + index - 1;
    // console.log('WMF scrollOffsetPercent ', scrollOffsetInPercent);
    scrollPercent -= scrollOffsetInPercent;
    return (
      <React.Fragment>
        <CustomerDetail src={customerDetailImg} scroll={scrollPercent} alt="customerDetails" />
        <Customers src={customersImg} scroll={scrollPercent} alt="customers" />
        <Alert src={alertImg} scroll={scrollPercent} alt="Alert" />
        <Task src={taskImg} scroll={scrollPercent} alt="Task" />
      </React.Fragment>
    );
  }
}

LashicImages.propTypes = {
  boxHeight: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  screenHeight: PropTypes.number.isRequired,
  scrollHeight: PropTypes.number.isRequired,
  scrollPercent: PropTypes.number.isRequired,
};

export default LashicImages;
