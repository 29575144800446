import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import eyepTabletImg from '../../../../Assets/Images/Eyep/Tablet.png';
import eyepIphoneImg from '../../../../Assets/Images/Eyep/Iphone.png';
import eyepAndroidImg from '../../../../Assets/Images/Eyep/Android.png';
import eyepLaptopImg from '../../../../Assets/Images/Eyep/Laptop.png';



const Iphone = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 12}%)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom:-30vh;
left: 5vw;
/* border: 1px dashed red; */
height: 50vh;
`;

const Tablet = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 5}%) scale(0.94)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -7vh;
right:4vw;
/* border: 1px dashed red; */
height: 50vh; 
`;

const Android = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 3}%) scale(0.8)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -55vh;
right:3vw;
/* border: 1px dashed red; */
height: 55vh; 
`;

const Laptop = styled.img.attrs({
  style: ({ scroll }) => ({
    transform: `translate(0px,-${(scroll) * 15}%) scale(0.9)`,
  }),
})`
transition: transform 0.2s ease-out;
position: absolute;
bottom: -70vh;
right:22vw;
/* border: 1px dashed red; */
height: 35vh; 
`;

class EyepImages extends Component {
  render() {
    let { scrollPercent } = this.props;
    const {
      boxHeight, index, scrollHeight, screenHeight,
    } = this.props;
    const heighttoBeReducedinVH = ((boxHeight * index) - 100);
    const scrollOffset = (screenHeight * heighttoBeReducedinVH) / 100;
    const scrollOffsetInPercent = (scrollOffset * 100 / scrollHeight) + index - 1;
    scrollPercent -= scrollOffsetInPercent;

    return (
      <React.Fragment>
        <Tablet src={eyepTabletImg} scroll={scrollPercent} alt="eyepTablet" />
        <Iphone src={eyepIphoneImg} scroll={scrollPercent} alt="eyepIphone" />
        <Android src={eyepAndroidImg} scroll={scrollPercent} alt="eyepAndroid" />
        <Laptop src={eyepLaptopImg} scroll={scrollPercent} alt="eyepLaptop" />
      </React.Fragment>
    );
  }
}

EyepImages.propTypes = {
  boxHeight: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  screenHeight: PropTypes.number.isRequired,
  scrollHeight: PropTypes.number.isRequired,
  scrollPercent: PropTypes.number.isRequired,
};

export default EyepImages;
